var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CoreFullscreen",
    {
      ref: "slideshowRenderer",
      staticClass: "slideshow-renderer",
      style: { height: _vm.contentHeight },
      on: {
        changeFullscreen: function ($event) {
          _vm.isInFullscreen = $event
        },
      },
    },
    [
      _c(
        "UiIconButton",
        {
          staticClass: "btn",
          attrs: {
            ariaLabel: _vm.isInFullscreen
              ? _vm.$tr("exitFullscreen")
              : _vm.$tr("enterFullscreen"),
            color: "primary",
            size: "small",
          },
          on: {
            click: function ($event) {
              return _vm.$refs.slideshowRenderer.toggleFullscreen()
            },
          },
        },
        [
          _vm.isInFullscreen
            ? _c("KIcon", {
                staticStyle: { top: "0", width: "24px", height: "24px" },
                attrs: {
                  icon: "fullscreen_exit",
                  color: _vm.$themeTokens.textInverted,
                },
              })
            : _c("KIcon", {
                staticStyle: { top: "0", width: "24px", height: "24px" },
                attrs: {
                  icon: "fullscreen",
                  color: _vm.$themeTokens.textInverted,
                },
              }),
        ],
        1
      ),
      _vm.slides.length
        ? _c(
            "Hooper",
            {
              ref: "slider",
              on: { slide: _vm.handleSlide, loaded: _vm.initializeHooper },
            },
            [
              _vm._l(_vm.slides, function (slide, index) {
                return _c(
                  "Slide",
                  { key: slide.id + index, attrs: { index: index } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "slideshow-slide-image-wrapper",
                        style: {
                          height: "calc(100% - " + _vm.captionHeight + "px)",
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "slideshow-slide-image",
                          attrs: {
                            src: slide.storage_url,
                            "aria-labelledby": _vm.slideTextId(slide.id),
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "visuallyhidden",
                        attrs: { id: _vm.slideTextId(slide.id) },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(slide.descriptive_text || slide.caption) +
                            "\n      "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        ref: slide.id,
                        refInFor: true,
                        staticClass: "caption",
                        style: { background: _vm.$themePalette.grey.v_200 },
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(slide.caption) + "\n      "
                        ),
                      ]
                    ),
                  ]
                )
              }),
              _c("HooperNavigation", {
                class: { "hooper-navigation-fullscreen": _vm.isInFullscreen },
                attrs: { slot: "hooper-addons" },
                slot: "hooper-addons",
              }),
              _c("HooperPagination", {
                style: {
                  background: _vm.$themePalette.grey.v_400,
                  width: "100%",
                },
                attrs: { slot: "hooper-addons" },
                slot: "hooper-addons",
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }